import React from "react";
import "twin.macro";

const Tag = ({ name }) => (
  <span tw="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-body text-gray-700 mr-2 mb-2">
    #{name}
  </span>
);

export default Tag;
