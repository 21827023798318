import React, { useState } from "react";
import "twin.macro";
import { graphql, Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Tag from "../../components/atoms/Tag";

const Article = ({
  id,
  slug,
  frontmatter: { title, subtitle, coverImage, tags }
}) => {
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <Link key={id} to={`/${slug}`} tw="max-w-4xl w-full inline-block">
      <img
        tw="hidden"
        alt="hidden cover"
        onLoad={() => setImageLoaded(true)}
        src={coverImage}
      />
      <div tw="max-w-5xl w-full md:flex hover:shadow-xl transition duration-150 ease-in-out">
        <div
          tw="transition duration-300 ease-in-out bg-gray-400 h-48 md:h-auto md:w-48 flex-none bg-cover rounded-t md:rounded-t-none md:rounded-l text-center overflow-hidden bg-cover bg-center"
          style={
            isImageLoaded
              ? { backgroundImage: `url('${coverImage}')`, opacity: 1 }
              : { opacity: 0.5 }
          }
          title={title}
        ></div>
        <div tw="w-full border-r border-b border-l border-gray-300 md:border-l-0 md:border-t md:border-gray-300 bg-white rounded-b md:rounded-b-none md:rounded-r p-8 pr-10 flex flex-col justify-between leading-normal">
          <div tw="mb-8">
            <div tw="font-heading text-black font-bold text-xl mb-2">
              {title}
            </div>
            <p tw="font-body text-gray-800 text-base">{subtitle}</p>
          </div>
          <div tw="pr-6">
            {tags.map((tag) => (
              <Tag name={tag} />
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
};

const Articles = ({
  data: {
    allMdx: { edges }
  }
}) => (
  <Layout>
    <SEO title="บทความ" />
    <section title="articles" tw="flex justify-center w-full">
      {edges.map((edge) => (
        <>
          <Article {...edge.node} />
        </>
      ))}
    </section>
  </Layout>
);

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            coverImage
            title
            subtitle
            tags
            date(formatString: "DD MMMM YYYY", locale: "TH")
          }
          slug
        }
      }
    }
  }
`;

export default Articles;
