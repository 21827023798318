import "twin.macro";
import React from "react";
import PropTypes from "prop-types";

import Header from "./header";
import "tailwindcss/dist/base.min.css";

const Layout = ({ children, ...props }) => {
  return (
    <>
      <Header />
      <section name="body" tw="pt-0 py-8 container mx-auto">
        {children}
      </section>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
